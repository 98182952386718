import React, { useEffect, useState, useRef, useCallback } from "react";

import USA from "../img/svg/usa_base.svg";
import statenames from "../data/state_abbrev_to_name.json";
import MapPopup from "./MapPopup";
import "../styles/usamap.css";
import "../styles/usamap-v2.css";
import {getMapsWithGrades} from '../utils/gradeUtils'
import { navigate } from 'gatsby';

const cardWidth = 386
const cardWidthNoGrades = 250
const hoverCardBp = 768

const styleElement = (elem, properties) => {
  Object.keys(properties).forEach((prop) => {
    elem.style[prop] = properties[prop]
  })
}

const setPopupLeftPosition = (defaultLeft, hasGrades) => {
  const screenWidth = window.innerWidth 
  const currentCardWidth = hasGrades ? cardWidth : cardWidthNoGrades
  
  // when going 60% of the map, send card to the left
  if (defaultLeft > (0.6 * screenWidth)) {
    return defaultLeft - currentCardWidth
  }

  if ((defaultLeft + currentCardWidth) > screenWidth) {
    return screenWidth - currentCardWidth - 20 // 20 is the space between the end of screen and card
  }

  return defaultLeft
}

function getCenterPosition(elem) {
  const rect = elem.getBoundingClientRect();
  return { 
    centerX: rect.left + rect.width / 2, 
    centerY: rect.top + rect.height / 2 
  };
}
    
const USAMapV2 = (props) => {
  const  [hoverCard, setHoverCard] = useState({})
  const usaMapViewRef = useRef()

  const updateHoverCard = useCallback((x, y, stateAbbreviation, state, stateGrades ) => {
    setHoverCard({
      x,
      y,
      stateAbbreviation,
      state,
      partisanAdvantage: stateGrades?.partisanAdvantage,
      gradeMaps: stateGrades.maps
      .filter(item => item.data?.grade?.finalReportCardGrade)
      .sort((a, b) => a.data.ChamberOrDelegationName.localeCompare(b.data.ChamberOrDelegationName)),
    })
  })

  useEffect(() => {

    const handleMouseLeave = () => {
      if (window.innerWidth > hoverCardBp) setHoverCard({})
    };

    if (usaMapViewRef.current) {
      usaMapViewRef.current.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        usaMapViewRef.current.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [usaMapViewRef]);
  
  useEffect(() => {
    let activeState = null

    const updateMap = async () => {      
      let stateGrades = {}

      for (const state of Object.keys(statenames)) {
        let currentStateMaps = props.maps.filter((item) => item.data.DashboardPlanStateAbbreviation[0] === state)

        let defaultStateColor = props.mapColors['N/A']
        stateGrades[state] = { maps: [], searched: false, hasGrades:false };        

        // Get the grades data and then Iterate over chambers to get defualt color based on the grade information.
        const results = await getMapsWithGrades(currentStateMaps, state)

        results.forEach(({ state, chamberMap, grades }) => {
          if (grades?.partisanAdvantage) {
            stateGrades[state].partisanAdvantage = grades.partisanAdvantage;
          }
      
          if (grades?.finalReportCardGrade) {
            chamberMap.data.grade = grades;
            stateGrades[state].maps.push(chamberMap)
            stateGrades[state].hasGrades = true
            if (defaultStateColor === props.mapColors['N/A']) {
              defaultStateColor = props.mapColors[grades.finalReportCardGrade.toUpperCase()];
            }
          }
        });

        const elem = document.getElementById(state);        
        if (!elem) return

        styleElement(elem, {stroke: "", strokeWidth: "", fill: defaultStateColor})

        elem.onclick = async e => {
          e.stopPropagation();
          typeof window !== "undefined" && window.gtag && window.gtag('event', 'select_content',{
            content_type: `usa-map-state_${props.mapKey}`,
            item_id: `${state}`
          });

          if (window.innerWidth > hoverCardBp) {
            const defaultUrl = stateGrades[state].maps[0]?.data.Live_URL
            if (defaultUrl){
              const urlObject = new URL(defaultUrl);
              const relativeUrl = `${urlObject.pathname}${urlObject.search}`;
              navigate(relativeUrl)
            }

          } else {
            const mapCurrentPosition = usaMapViewRef.current.getBoundingClientRect(); 
            updateHoverCard(
              '50%',
              e.clientY - mapCurrentPosition.y,
              state,
              statenames[state],
              stateGrades[state],
              )
          }
        };

        elem.onmouseenter = async e => {
          if (activeState) {
            const active = document.getElementById(activeState)
            styleElement(active, {stroke: "", strokeWidth: ""})
          } 
          activeState = state
          styleElement(elem, {stroke: "white", strokeWidth: 5})

          if (window.innerWidth < hoverCardBp) return

          const { centerX, centerY } = getCenterPosition(elem)
          const mapCurrentPosition = usaMapViewRef.current.getBoundingClientRect(); 

          updateHoverCard(
            setPopupLeftPosition(centerX, stateGrades[state]?.hasGrades) - mapCurrentPosition.x,
            centerY - mapCurrentPosition.y + 20,
            state,
            statenames[state],
            stateGrades[state]
          )
        };
      }
    };

    updateMap();
  }, []);

  return (
    <div className="usa-map-v2" key={props.mapKey}>
      {
        !props.hideInfoPanel &&
        <div className="usa-map-info">
          <div className="usa-map-description">
            <div className="usa-map-title">
              {props.mapTitle}
            </div>

          </div>
        </div>
      }
      <div className="usa-map-view" ref={usaMapViewRef}> 
      <div style={{ border : '1px solid transparent'}}>
        <USA />
      </div>
      {
      Object.keys(hoverCard).length > 0 && 
        <MapPopup 
          popupOpen = {true}
          onClose = {() => setHoverCard({})}
          partisian={hoverCard.partisanAdvantage}
          gradeItems={hoverCard?.gradeMaps} 
          modifierClass='usa-map-v2-hover' 
          state = {hoverCard.state}
          stateAbbreviation = {hoverCard.stateAbbreviation}
          style={{ display: 'flex', top: hoverCard.y, left: hoverCard.x }} 
        /> 
      }
      <ul className="legend-values">
        {
          props.legend &&
          props.legend.map(({key, label}) => {
            return (
              <li key={key} className="item-legend">
                {props.mapColors[key.toUpperCase()] && <span style={{ background : props.mapColors[key.toUpperCase()]}}></span>}
                <p dangerouslySetInnerHTML={{__html: label}} />
              </li>
            )
          })
        }
      </ul>
      </div>
    </div>
  );
};

export default USAMapV2;