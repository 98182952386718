import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import "../../styles/redistricting-dashboard-homepage.css";
import USAMapV2 from "../USAMapV2.jsx";
import { MAP_COLORS } from "../../common/constants/colors-dashboard.js"

const RedistrictingDashboardMapv2 = (props) => {
  const data = useStaticQuery(graphql`
  query dashboardHomeQueryv2 {
    allAirtableDashboard(
      filter: {table: {eq: "Dashboard"}, data: {DashboardPlanStatus: {eq: "Live"}, DashboardFinalPlan: {eq: true},}}
      sort: {fields: data___DateAdded, order: DESC}
    ) {
      totalCount
      nodes {
        id
        recordId
        data {
          ChamberOrDelegationName
          DashboardPlanMapURL
          DashboardPlanScoreURL
          DashboardPlanName
          DashboardPlanStateName
          DashboardPlanStateAbbreviation
          DashboardPlanState
          DashboardPlanStatus
          DashboardPlanYear
          DashboardType
          DashboardChamber
          DashboardFinalPlan
          DashboardTabledPlan
          GeneratedScoreURL
          Live_URL
          DateAdded
        }
      }
    }
  }`);
    return (
        <USAMapV2
            maps={data.allAirtableDashboard.nodes}
            legend = {[
              {
                key : "A",
                label: "<strong>A:</strong> Good",
              },
              {
                key : "B",
                label: "<strong>B:</strong> Better than average with some bias",
              },
              {
                key : "C",
                label: "<strong>C:</strong> Average",
              },              
              {
                key : "D",
                label: "<strong>D/F:</strong> Poor",
              },
              {
                key : "N/A",
                label: "Insufficient Data",
              }
            ]}
            mapColors = {MAP_COLORS}
            key={"redistricting-report-card"}
            mapKey={"redistricting-report-card"}
            mapTitle={<>
            <Link to={`/redistricting-report-card`}>
                Redistricting Report Card
            </Link>
            </>}
            statusFieldName={`totalCount`}
        />
    );
  }
  
  export default RedistrictingDashboardMapv2;