import React from "react";

const ArrowIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 17 18" fill="none">
            <path d="M9.78864 12.3966L13 9.01725L9.78864 5.63789C9.75964 5.59802 9.72279 5.56513 9.68059 5.54147C9.63839 5.5178 9.59183 5.5039 9.54406 5.50071C9.49628 5.49752 9.44841 5.50511 9.40368 5.52297C9.35895 5.54083 9.31841 5.56855 9.2848 5.60424C9.2512 5.63993 9.2253 5.68276 9.20888 5.72983C9.19246 5.77691 9.1859 5.82713 9.18963 5.87709C9.19336 5.92705 9.2073 5.97559 9.23051 6.01943C9.25371 6.06326 9.28565 6.10136 9.32414 6.13115L11.7268 8.66742L5.33417 8.66742C5.24554 8.66742 5.16055 8.70427 5.09788 8.76988C5.03521 8.83548 5 8.92447 5 9.01725C5 9.11003 5.03521 9.19901 5.09788 9.26461C5.16055 9.33022 5.24554 9.36708 5.33417 9.36708L11.7268 9.36708L9.32414 11.9033C9.26166 11.9692 9.22674 12.0584 9.22705 12.1512C9.22736 12.244 9.26289 12.3329 9.32581 12.3984C9.38874 12.4638 9.47391 12.5003 9.56258 12.5C9.65126 12.4997 9.73618 12.4625 9.79866 12.3966L9.78864 12.3966Z" fill="currentcolor"/>
        </svg>

    )
}

export default ArrowIcon;