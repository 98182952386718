import React from "react";

const CloseIcon = (props) => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.2499 4.75828C15.1728 4.68102 15.0813 4.61973 14.9805 4.57792C14.8796 4.5361 14.7716 4.51457 14.6624 4.51457C14.5533 4.51457 14.4452 4.5361 14.3444 4.57792C14.2436 4.61973 14.152 4.68102 14.0749 4.75828L9.99994 8.82494L5.92494 4.74994C5.84779 4.67279 5.7562 4.61159 5.65539 4.56984C5.55459 4.52808 5.44655 4.50659 5.33744 4.50659C5.22833 4.50659 5.12029 4.52808 5.01949 4.56984C4.91869 4.61159 4.82709 4.67279 4.74994 4.74994C4.67279 4.82709 4.61159 4.91869 4.56984 5.01949C4.52808 5.12029 4.50659 5.22833 4.50659 5.33744C4.50659 5.44655 4.52808 5.55459 4.56984 5.65539C4.61159 5.7562 4.67279 5.84779 4.74994 5.92494L8.82494 9.99994L4.74994 14.0749C4.67279 14.1521 4.61159 14.2437 4.56984 14.3445C4.52808 14.4453 4.50659 14.5533 4.50659 14.6624C4.50659 14.7715 4.52808 14.8796 4.56984 14.9804C4.61159 15.0812 4.67279 15.1728 4.74994 15.2499C4.82709 15.3271 4.91869 15.3883 5.01949 15.43C5.12029 15.4718 5.22833 15.4933 5.33744 15.4933C5.44655 15.4933 5.55459 15.4718 5.65539 15.43C5.7562 15.3883 5.84779 15.3271 5.92494 15.2499L9.99994 11.1749L14.0749 15.2499C14.1521 15.3271 14.2437 15.3883 14.3445 15.43C14.4453 15.4718 14.5533 15.4933 14.6624 15.4933C14.7715 15.4933 14.8796 15.4718 14.9804 15.43C15.0812 15.3883 15.1728 15.3271 15.2499 15.2499C15.3271 15.1728 15.3883 15.0812 15.43 14.9804C15.4718 14.8796 15.4933 14.7715 15.4933 14.6624C15.4933 14.5533 15.4718 14.4453 15.43 14.3445C15.3883 14.2437 15.3271 14.1521 15.2499 14.0749L11.1749 9.99994L15.2499 5.92494C15.5666 5.60828 15.5666 5.07494 15.2499 4.75828Z" fill="#959595"/>
      </svg>
    )
  }

export default CloseIcon;