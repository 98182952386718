import axios from 'axios'

export const CHAMBER = {
  CONGRESSIONAL : 'Congressional',
  SENATE_HOUSE : 'Senate/House',
  SENATE : 'Senate',
  HOUE : 'House',
}

function getChamberOrderIndex(chamberName) {
  const order = Object.values(CHAMBER)
  const index = order.indexOf(chamberName);
  return index === -1 ? order.length : index
}

// Sorting function for chamber maps so default color follows order from the CHAMBER object. First Congressional, ....
function sortChamberMaps(chamberMaps) {
  return chamberMaps.sort((a, b) => {
    const chamberA = a.data?.ChamberOrDelegationName;
    const chamberB = b.data?.ChamberOrDelegationName;
    return getChamberOrderIndex(chamberA) - getChamberOrderIndex(chamberB);
  });
}

export async function fetchGradeData(url){
    try {
      const response = await axios.get(url);
      return response.data?.report_card || false;
    } catch (error) {
      console.error('Error fetching data:', error);
      return false;
    }
  };

  export async function getMapsWithGrades(currentStateMaps, state) {
    let processedChambers = []
  
    const filteredMaps = currentStateMaps.filter((map) => {
      const chamber = map.data?.ChamberOrDelegationName
      if (processedChambers.includes(chamber)) return false
      processedChambers.push(chamber)
      return true
    })

    const sortedFilteredMaps = sortChamberMaps(filteredMaps); 

    const promises = sortedFilteredMaps.map(async chamberMap => {
      const grades = await fetchGradeData(chamberMap.data.GeneratedScoreURL);
      return { state, chamberMap, grades };
    });

    return await Promise.all(promises);
  }