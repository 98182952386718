export const COMPETITIVENESS_COLORS = {
    // vega - redblue
    LIKELY_DEMOCRAT: '#2f78b3',
    LEAN_DEMOCRAT: '#9dcae1',
    LEAN_REPUBLICAN: '#f4ae91',
    LIKELY_REPUBLICAN: '#bf363a',
    FOURTY_FIVE_FIFTY_FIVE_PERCENT: 'lightgrey',

    // akmetz suggestion
    // LIKELY_DEMOCRAT: '#2F4CB7',
    // LEAN_DEMOCRAT: '#6E86DD',
    // LEAN_REPUBLICAN: '#ED9799',
    // LIKELY_REPUBLICAN: '#B7484B',
    // FOURTY_FIVE_FIFTY_FIVE_PERCENT: 'lightgrey',

    // LIKELY_DEMOCRAT: 'rgb(0, 157, 220)',
    // LEAN_DEMOCRAT: 'lightblue',
    // LEAN_REPUBLICAN: 'pink',
    // LIKELY_REPUBLICAN: 'rgb(184, 74, 98)',
    // FOURTY_FIVE_FIFTY_FIVE_PERCENT: 'lightgrey',
};

export const VAP_COLORS = {
    // purple
    // ZERO_THIRTY_PERCENT: '#000000',
    // THIRTY_FOURTY_PERCENT: '#FFF2FC',
    // FOURTY_FIFTY_PERCENT: '#E3D6DE',
    // FIFTY_HUNDRED_PERCENT: '#C7BAC0',
    
    // inverted grey
    ZERO_THIRTY_PERCENT: '#FFFFFF',
    THIRTY_FOURTY_PERCENT: '#C4C4C4',
    FOURTY_FIFTY_PERCENT: '#9D9D9D',
    FIFTY_HUNDRED_PERCENT: '#757575',
    
    
    // grey
    // ZERO_THIRTY_PERCENT: '#000000',
    // THIRTY_FOURTY_PERCENT: 'lightgrey',
    // FOURTY_FIFTY_PERCENT: 'darkgrey',
    // FIFTY_HUNDRED_PERCENT: 'grey',
    
    // yellow
    // THIRTY_FOURTY_PERCENT: '#f7e8a4',
    // FOURTY_FIFTY_PERCENT: '#f0d456',
    // FIFTY_SIXTY_PERCENT: '#ceac12',
    
    // blue
    // THIRTY_FOURTY_PERCENT: 'gainsboro',
    // FOURTY_FIFTY_PERCENT: 'lightsteelblue',
    // FIFTY_SIXTY_PERCENT: 'slategrey',
    // FIFTY_HUNDRED_PERCENT: 'slategrey',
};

export const REPORT_CARD_COLORS = {
    // vega - turbo
    A: '#4DF884',
    B: '#ECD12E',
    C: '#FFA423',
    D: '#F65F18',
    F: '#BA2208',
    PASS: '#4DF884',
    FAIL: '#BA2208',

    // vega - lightmulti
    // A: '#caebd7',
    // B: '#bddf93',
    // C: '#f6e072',
    // D: '#f4a946',
    // F: '#f56c3f',
    // PASS: '#caebd7',
    // FAIL: '#f56c3f',

    // greyscale
    // A: '#D4D4D4',
    // B: '#c4c4c4',
    // C: '#b1b1b1',
    // D: '#9d9d9d',
    // F: '#757575',
    // PASS: '#D4D4D4',
    // FAIL: '#757575',

    // akmetz suggestion
    // A: '#70AC5D',
    // B: '#F8EB5C',
    // C: '#F7B749',
    // D: '#EB786F',
    // F: '#96738D',
    // PASS: '#70AC5D',
    // FAIL: '#96738D',

    // more npr style colors
    // A: '#70AC5D',
    // B: '#CDBA65',
    // C: '#C9A47E',
    // D: '#EB786F',
    // F: '#96738D',
    // PASS: '#70AC5D',
    // FAIL: '#96738D',

    // // purple for F
    // A: '#70AC5D',
    // B: '#b0c289',
    // C: '#d4c37a',
    // D: '#cdab87',
    // F: '#96738D',
    // PASS: '#70AC5D',
    // FAIL: '#96738D',

    // C: '#c2c282',
    // F: '#c39195',
    // FAIL: '#c39195',
    // ~ 10% lighter 
        // A: '#c3d798',
        // B: '#d8d890',
        // C: '#ecd988',
        // D: '#e4be96',
        // F: '#d9a1a5',
        // PASS: '#c3d798',
        // FAIL: '#d9a1a5',
    // A: 'rgba(127, 184, 0, .5)',
    // B: 'rgba(226, 211, 141, .5)',
    // C: 'rgba(164, 123, 39, .5)',
    // D: 'rgba(213, 137, 109, .5)',
    // F: 'rgba(200, 62, 77, .5)',
    // PASS: 'rgba(127, 184, 0, .5)',
    // FAIL: 'rgba(200, 62, 77, .5)',
}

export const MAP_COLORS = {
    A: '#78DB88',
    B: '#F8E963',
    C: '#F5B85D',
    D: '#EA655D',
    F: '#EA655D',
    "F (RACIAL)" : "#EA655D",
    PASS: '#78DB88',
    FAIL: '#EA655D',
    "N/A" : '#D7E9F2'
}

export const PARTISIAN_COLORS = {
    rep : '#EA655D',
    dem : 'blue'
}