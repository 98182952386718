import React from "react";
import { Link } from "gatsby";
import { PARTISIAN_COLORS } from "../common/constants/colors-dashboard.js"
import { MAP_COLORS } from "../common/constants/colors-dashboard.js"
import ArrowIcon from './icons/ArrowIcon.jsx'
import CloseButton from './icons/CloseIcon.jsx'
import "../styles/map-popup.css";

const PARTISIAN_ADVANTAGE = {
  rep : 'Republican',
  dem : 'Democratic'
}

const MapPopup = ({modifierClass, style, gradeItems, state, partisian, stateAbbreviation, onClose}) => {
  const partisianColor = partisian && PARTISIAN_COLORS[partisian.toLowerCase()] ? PARTISIAN_COLORS[partisian.toLowerCase()] : '#AAAEB4';
  const hasGradeItems = gradeItems.length > 0;

  return (
    <article className={`map-popup ${modifierClass} ${hasGradeItems ? '' : 'no-grades' }`} style={style}>
      <button aria-label="Close popup" className="close" onClick={onClose} > 
        <CloseButton />
      </button>
      <div className="left-container">
        <div className="top-container">
          <Link target="_blank" to={`/reforms/${stateAbbreviation}`} className='popup-title'>{state}</Link>
        </div>
        <p className='partisan'>PARTISAN ADVANTAGE</p>
        <div className='gov'>
          <span aria-hidden='true' style={{ background : partisianColor }}></span>
          {partisian && PARTISIAN_ADVANTAGE[partisian.toLowerCase()] ? PARTISIAN_ADVANTAGE[partisian.toLowerCase()] : 'None' }
        </div>
        <Link target="_blank" className="view-more" to={`/reforms/${stateAbbreviation}`}>View more</Link>
      </div>
      {hasGradeItems &&
        <ul className='card'>
          {
            gradeItems.map((item, index) => {
              const finalReportCardGrade = item.data?.grade?.finalReportCardGrade
              return (
                <li key={index}>
                  <Link target="_blank" to={item.data.Live_URL}>
                    <div style={{ position: 'relative'}}>
                      <span className="popup-chamber">{item.data.ChamberOrDelegationName}</span>
                      <ArrowIcon className='arrow-icon' />
                      </div> 
                    {finalReportCardGrade && <span className="popup-grade" style={{color: MAP_COLORS[finalReportCardGrade.toUpperCase()] ?? '' }} >{finalReportCardGrade}</span>}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      }
    </article>
  );
};

export default MapPopup;
